import { iframeResizer } from 'iframe-resizer';
import type { Ref } from 'vue';

export function useIframeResizer(
  iframeRef: Ref<HTMLIFrameElement | undefined>
) {
  // Inspired by https://github.com/davidjbradshaw/iframe-resizer/blob/master/docs/use_with/vue.md
  // Could use a directive if this should be reused.
  onMounted(() => {
    const iframeElement = iframeRef.value;
    if (iframeElement) {
      iframeElement.addEventListener('load', () =>
        iframeResizer({}, iframeElement)
      );
    }
  });

  onBeforeUnmount(() => {
    // XXX: proper typing
    (iframeRef.value as any)?.iFrameResizer?.removeListeners();
  });
}
